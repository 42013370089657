<template>
    <div class="monoTitle">
        <div id="weather-board" class="d-none d-sm-block">
            <h6>Weather: {{ city }}</h6>
            <span class="text-caption">{{ weatherMain }}: {{ weatherDesc }}</span>
            <h1>
                <i class="wi" :class="icon"></i>&nbsp;{{ curTemp }}
                
            </h1>
        </div>
        <div id="weather-board" class="d-block d-sm-none">
            {{ city }}: <i class="wi" :class="icon"></i>&nbsp;{{ curTemp }}
            <span class="text-caption">{{ weatherMain }}: {{ weatherDesc }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'weatherWidget',
        components: {
        },
        data() {
            return {
                latitude: "39.95",
                longitude: "-74.19",
                city: 'Toms River, NJ',
                dataObj: {
                    temp: 0,
                    current: {
                        weather: [{
                            description: "",
                            main: ""
                        }]
                    }
                },
                errorMsg: '',
            }
        },
        computed: {
            icon: function() {
                return `wi-owm-${this.dataObj.current.weather[0].id}`
            },
            curTemp: function() {
                return Math.round(this.dataObj.current.temp)
            },
            weatherDesc: function() {
                return this.dataObj.current.weather[0].description
            },
            weatherMain: function() {
                return this.dataObj.current.weather[0].main
            }
        },
        created() {
            this.getWeather();
            //console.log('App ready!');
        },
        methods: {   
            async getWeather() {
                var reqUrl = `https://api.openweathermap.org/data/3.0/onecall?lat=${this.latitude}&lon=${this.longitude}&units=imperial&exclude=minutely,hourly,daily&appid=fbeb1e95236b47b5d4ab4127c0d38f39`
                
                try {
                    const res = await this.$axios.get(reqUrl)
                    console.log(res)
                    this.dataObj = res.data
                } catch (err) {
                    console.error(err)
                }
                /*
                this.$axios.get(reqURL).then(response => {
                    this.dataObj = response.data
                    this.curTemp = Math.round(this.dataObj.main.temp)
                    this.city = this.dataObj.name
                    this.weatherMain = this.dataObj.weather[0].main
                    this.weatherDesc = this.dataObj.weather[0].description
                }, function(response) {
                    console.log(response)
                })
                */
            }
        }
    }
</script>

<style lang="css" scoped>

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Montserrat&display=swap');
@import url('../assets/css/weather-icons-min.css');

.weather-board {
    padding:  0.5em 0.8em 0.5em 0.8em;
    display: inline-block;
	line-height: 1.3em;
    background: #1C1C1C;
    border-radius: 0.51em;
    color: #eee;
	font-family: 'Montserrat', Helvetica;
    width: 1025px;
    position: absolute;
    bottom: 10px;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'



Vue.use(VueRouter)

const routes = [
  
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "Register" */ '../views/Register.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/jobProfile/:id',
    name: 'jobProfile',
    component: () => import(/* webpackChunkName: "JobProfile" */ '../views/JobProfile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/jobSetup/:id',
    name: 'jobSetup',
    component: () => import(/* webpackChunkName: "JobSetup" */ '../views/jobSetup.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/printJobSetup/:id',
    name: 'printJobSetup',
    component: () => import(/* webpackChunkName: "JobSetup" */ '../views/printJobSetup.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/serviceTix/:id',
    name: 'serviceTix',
    component: () => import(/* webpackChunkName: "JobProfile" */ '../views/serviceTix.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/upload/:id',
    name: 'upload',
    component: () => import(/* webpackChunkName: "JobProfile" */ '../views/upload.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "Search" */ '../views/Search.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/fileviewer',
    name: 'fileviewer',
    component: () => import(/* webpackChunkName: "FileViewer" */ '../views/fileViewer.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/map/:location',
    name: 'Map',
    component: () => import(/* webpackChunkname: "Map" */ '../views/Map.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: () => import(/* webpackChunkname: "Jobs" */ '../views/Jobs.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/viewContract/:id',
    name: 'viewContract',
    component: () => import(/* webpackChunkname: "viewContract" */ '../views/viewContract.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/viewInvoice/:id',
    name: 'viewInvoice',
    component: () => import(/* webpackChunkname: "viewInvoice" */ '../views/viewInvoice.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/copyContract/:id',
    name: 'copyContract',
    component: () => import(/* webpackChunkname: "contractEditor" */ '../views/contractEditor.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/newContract/',
    name: 'newContract',
    component: () => import(/* webpackChunkname: "newContractEditor" */ '../views/contractEditor.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/serviceBoard/',
    name: 'serviceBoard',
    component: () => import(/* webpackChunkname: "serviceBoard" */ '../views/serviceBoard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/email/',
    name: 'email',
    component: () => import(/* webpackChunkname: "serviceBoard" */ '../views/email.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/adminSettings',
    name: 'adminSettings',
    component: () => import(/* webpackChunkName: "JobProfile" */ '../views/adminSettings.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/accountsRecievable',
    name: 'accountsRecievable',
    component: () => import(/* webpackChunkName: "JobProfile" */ '../views/accountsRecievable.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/salesByMonth',
    name: 'salesByMonth',
    component: () => import(/* webpackChunkName: "JobProfile" */ '../views/salesByMonth.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/repeatJobs',
    name: 'repeatJobs',
    component: () => import(/* webpackChunkName: "JobProfile" */ '../views/repeatJobs.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/creditCardActivity',
    name: 'creditCardActivity',
    component: () => import(/* webpackChunkName: "JobProfile" */ '../views/creditCardActivity.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/notificationBoard',
    name: 'notificationBoard',
    component: () => import(/* webpackChunkName: "JobProfile" */ '../views/notificationBoard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "JobProfile" */ '../views/settings.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/customerPortal/:id',
    name: 'customerPortal',
    component: () => import(/* webpackChunkName: "JobProfile" */ '../views/customerPortal.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if(localStorage.getItem("jwt") == null) {
      next({ path: "/login"})
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router

<template>
    <div>
        <b-alert show v-for="note in notifications"
            :variant="note.noteType === 'payment' ? 'success' : note.noteType === 'review' ? 'info' : 'warning'"
            :key="note._id" class="d-flex mb-2 pt-1 pb-1 pr-0">
            <div class="flex-fill">
                <font-awesome-icon v-if="note.noteType == 'email'" icon="fa-solid fa-envelope-open-text" class="mr-1" />
                <font-awesome-icon v-else-if="note.noteType == 'review'" :icon="['far', 'fa-comment']" class="mr-1" />
                <font-awesome-icon v-else icon="fa-regular fa-credit-card" class="mr-1" />
                {{ note.text }}

                <b-button v-if="note.noteType == 'email' || note.noteType == 'payment'" variant="link"
                    :class="[isMobile ? 'text-left' : '']" class="pb-0 pt-0" @click="goToJob(note.job._id)">
                    {{ shortText(note.job.customer.lname) }}, {{ shortText(note.job.customer.fname) }}
                    {{ note.job.jobType }}: {{ note.job.siteAddress.street }}
                </b-button>

                <small><span class="text-muted">{{ format(fromUnixTime(note.date), 'MM/dd h:mm aaa') }}</span></small>
            </div>

            <b-button v-if="note.noteType == 'email' || note.noteType == 'payment'" variant="outline-primary" size="sm"
                @click="dismiss(note)" class="note-btn pt-0 pb-0 pl-1 pr-1 mr-0 ml-2 text-primary">
                <font-awesome-icon icon="fa-regular fa-circle-xmark" class="mr-1" />Archive
            </b-button>

            <b-button v-if="note.noteType == 'review'" variant="outline-dark" size="sm" @click="action(note)"
                class="note-btn pt-0 pb-0 pl-1 pr-1 mr-0 ml-2 text-primary">
                <font-awesome-icon :icon="['far', 'comments']" class="mr-1" />Yes
            </b-button>
            <b-button v-if="note.noteType == 'review'" variant="outline-dark" size="sm" @click="dismiss(note)"
                class="note-btn pt-0 pb-0 pl-1 pr-1 mr-0 ml-2 text-primary">
                <font-awesome-icon :icon="['far', 'circle-xmark']" class="mr-1" />No
            </b-button>

        </b-alert>

        <b-modal id="sendTxtModal" title="Confirm Message Text" size="md" @ok.prevent="sendMsg" ok-title="Send Text"
            ok-variant="primary" header-bg-variant="primary" header-text-variant="light">

            <b-form-group label="To:">
                <b-form-input id="toNumberTxt" v-model="txtMsg.toNumber" trim
                    :state="txtMsg.toNumberErrState"></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">{{ txtMsg.toNumberErrTxt }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Name:">
                <b-form-input id="nameTxt" v-model="txtMsg.toName" trim :state="txtMsg.toNameErrState"></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">{{ txtMsg.toNameErrTxt }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Job Type:">
                <b-form-input id="nameTxt" v-model="txtMsg.msgJobType" trim :state="txtMsg.msgJobTypeErrState"></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">{{ txtMsg.msgJobTypeErrState }}</b-form-invalid-feedback>
            </b-form-group>
            Message Preview:
            <p class="txtMsg send">{{ msgTxt }}</p>

        </b-modal>
    </div>
</template>

<script>
import { getUnixTime, fromUnixTime, format } from "date-fns"

export default {
    name: 'notifications',
    data() {
        return {
            notifications: [],
            format: format,
            fromUnixTime: fromUnixTime,
            txtMsg: {
                note: null,
                fromNumber: null,
                toName: "",
                toNameErrTxt: "",
                toNameErrState: null,
                toNumber: null,
                toNumberErrTxt: "",
                toNumberErrState: null,
                msgJobType: "",
                msgJobTypeErrTxt: "",
                msgJobTypeErrState: null,
                jobId: null
            }
        }
    },
    computed: {
        userInfo() {
            return this.$state.userInfo
        },
        isMobile() {
            return this.$state.isMobile
        },
        ready() {
            if (this.$state.sessionIntiated) {
                return true
            } else {
                return false
            }
        },
        msgTxt() {
            return `Hi ${this.txtMsg.toName},\nWe hope you're enjoying your ${this.txtMsg.msgJobType}! We'd love to hear about your experience. Would you mind leaving us a 5-star review on Google? https://bit.ly/TwinRate Thanks, it really helps! -Twin`
        }
    },
    methods: {
        shortText(val) {
            if (val.trim().length > 12) {
                return `${val.substring(0, 12)} ...`
            } else {
                return val
            }
        },
        async checkNotifications() {

            if (!this.$state.userInfo._id) {
                console.log(`Notify Not ready yet! Waiting for initsession to complete.`)
                setTimeout(() => { this.checkNotifications() }, 100)
            } else {

                const user = this.$state.userInfo._id

                const res = await this.$axios.get(`/notify/user/${user}`, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })

                const unRead = res.data.filter((note) => note['read'] === false)
                this.notifications = unRead
                this.$state.notifications = unRead.length
                //console.log(this.notifications)

            }

        },
        goToJob(_id) {
            this.$router.push(`/jobProfile/${_id}`)
        },
        async dismiss(note) {
            let payload = note
            payload.read = true
            try {
                const res = await this.$axios.put(`/notify/${payload._id}`, payload, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })

                console.log(res)
                this.$state.notifications = this.$state.notifications - 1
                const newNotifcations = this.notifications.filter((note) => note['_id'] !== payload._id)
                this.notifications = newNotifcations

            } catch (err) {
                console.log(err)
            }
        },
        action(note) {
            console.log(`The action method was run`)
            if (note.noteType === 'review') {
                console.log('User has selected to send a review text message.')
                this.txtMsg.note = note
                //start building text message
                //To send a text message we need a fromNumber, toNumber, and msgTxt
                this.txtMsg.fromNumber = '732-240-3780' //Twin's main line

                this.txtMsg.toName = note.job.customer.fname
                this.txtMsg.jobId = note.job._id

                if (note.job.jobType == 'Siding') {
                    this.txtMsg.msgJobType = "new siding"
                } else if (note.job.jobType == 'Rip ReRoof' || note.job.jobType == 'New Construction' || note.job.jobType == 'Cover Roof') {
                    this.txtMsg.msgJobType = "new roofing"
                } else if (note.job.jobType == 'Windows') {
                    this.txtMsg.msgJobType = "new windows"
                } else if (note.job.jobType == 'Gutters') {
                    this.txtMsg.msgJobType = "new gutters"
                } else if (note.job.jobType == 'Roof Repair') {
                    this.txtMsg.msgJobType = "recent roof repair"
                } else if (note.job.jobType == 'Siding Repair') {
                    this.txtMsg.msgJobType = "recent siding repair"
                } else {
                    this.txtMsg.msgJobType = "recent job"
                }
                //does the customer have a Mobile number? 
                if (note.job.customer.phones.mobile.trim() !== '') {
                    this.txtMsg.toNumber = note.job.customer.phones.mobile
                }

                console.log(`Data collect for msg is:`)
                console.log(this.txtMsg)
                this.$bvModal.show('sendTxtModal')

            }
        },
        async sendMsg() {
            console.log("Send message was pressed.")

            //Check to see that there is no missing data...
            //toNumber cant be blank
            if (this.txtMsg.toNumber.trim() == "") {
                this.txtMsg.toNumberErrTxt = "The 'To:' field can not be blank!"
                this.txtMsg.toNumberErrState = false
                return
            } else {
                this.txtMsg.toNumberErrState = true
            }
            
            //toNumber can only contain numerals and '-'
            const regex = /^[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}$/

            //The regular expression you provided, ^[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}$

            // ^: This matches the beginning of the string. So, the entire text you're checking should start with the pattern that follows.
            // [0-9]{3}: This part matches three consecutive digits (from 0 to 9) exactly three times.
            // [-]: This matches a literal hyphen "-" character.
            // ?: This quantifier makes the preceding element (the hyphen in this case) optional. So, it can appear zero or one time.
            // The above pattern ([0-9]{3}[-]?) is repeated twice more to match three sets of digits potentially separated by hyphens.
            // $: This matches the end of the string. So, the entire text you're checking should end with the pattern that precedes this.
            // Here are some examples of valid strings that this regex would match:

            // 123-456-7890
            // 1234567890 (without hyphens)

            if (!regex.test(this.txtMsg.toNumber)) {
                this.txtMsg.toNumberErrTxt = "The 'To:' fieldmust be a properly formatted phone number and can only contain 0-9 and the '-' character. i.e. 732-240-3780"
                this.txtMsg.toNumberErrState = false
                return
            } else {
                this.txtMsg.toNumberErrState = true
            }
            //toName cant be blank
            if (this.txtMsg.toName.trim() == "") {
                this.txtMsg.toNameErrTxt = "The 'Name:' field can not be blank!"
                this.txtMsg.toNameErrState = false
                return
            } else {
                this.txtMsg.toNameErrState = true
            }

            //msgJobType cant be blank
            if (this.txtMsg.msgJobType.trim() == "") {
                this.txtMsg.msgJobTypeErrTxt = "The 'Job Type:' field can not be blank!"
                this.txtMsg.msgJobTypeErrState = false
                return
            } else {
                this.txtMsg.msgJobTypeErrState = true
            }

            const payload = {
                to_Number: this.txtMsg.toNumber.replace(/-/g, ""),
                from_Number: this.txtMsg.fromNumber.replace(/-/g, ""),
                text: this.msgTxt
            }
            console.log("sending text message...")

            try {
                const res = await this.$axios.post('/dialpad/', payload, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })

                console.log(`Response recieved. txt status is: ${res.status.toString()}`)

                if (res.status === 200) {

                    //notify user of the success
                    this.$bvToast.toast('👍🏻 Text message was sent.', {
                        title: 'Success',
                        variant: 'success',
                        solid: true
                    })

                    //log the text message
                    const newLog = {
                        logTime: getUnixTime(new Date()),
                        logMsg: `Thank You/Review text messaage was sent.`,
                        addInfo: `The message was sent to ${this.txtMsg.toNumber} from ${this.txtMsg.fromNumber}. The message text was: ${this.msgTxt}`,
                        job: this.txtMsg.jobId,
                        logUser: this.userInfo.name
                    }

                    this.$actions.log(newLog)

                    //dismiss notification
                    this.dismiss(this.txtMsg.note)

                } else {
                    console.error(res)
                    this.$bvToast.toast(`Woops. Error Status text is: ${res.errorText}  👎🏻`, {
                        title: 'Oh no!',
                        variant: 'danger',
                        solid: true
                    })
                }

                this.$bvModal.hide('sendTxtModal')

            } catch (error) {
                console.log(error)
                this.$bvToast.toast('Something went wrong. Check the console for errors. 👎🏻', {
                    title: 'Oh no!',
                    variant: 'danger',
                    solid: true
                })

                this.$bvModal.hide('sendTxtModal')

            }

            


        }
    },
    mounted() {

        this.checkNotifications()
    }
}
</script>

<style lang="scss" scoped>
.note-btn {
    width: 100px;
}

.hide-long-stuff {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.txtMsg {
    max-width: 355px;
    word-wrap: break-word;
    margin-bottom: 12px;
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
    line-height: 24px;
    position: relative;
    padding: 10px 20px;
    border-radius: 25px;

    &:before,
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 25px;
    }
}

.send {
    color: white;
    background: #5BC236;
    align-self: flex-end;

    &:before {
        right: -7px;
        width: 20px;
        background-color: #5BC236;
        border-bottom-left-radius: 16px 14px;
    }

    &:after {
        right: -26px;
        width: 26px;
        background-color: white;
        border-bottom-left-radius: 10px;
    }
}
</style>
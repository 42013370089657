<template>
  <b-container fluid="md" :class="[isMobile ? 'mt-5 pt-5 mb-5 pb-4' : 'mt-3' ]">
    <b-row v-if="isMobile">
      <b-col>
        <notifications class="mt-3" />
      </b-col>  
    </b-row>
    <b-row>
      <b-col sm="3">
        <b-row >
          <b-col cols="12">
            <weather-widget :class="['mb-1', {'pl-2':isMobile} ]" />
          </b-col>
          <b-col cols="12">
            <in-progress />
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="9">
        <notifications v-if="!isMobile" />
        <my-recent-contracts /> 
        <recent-others-contracts />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import weatherWidget from '@/components/weather-widget.vue'
import myRecentContracts from '@/components/myRecentContracts.vue'
import inProgress from '@/components/inProgress.vue'
import recentOthersContracts from '@/components/recentOthersContracts.vue'
import notifications from '@/components/notifications.vue'


export default {
  name: "dashboard",
  components: {
    weatherWidget,
    myRecentContracts,
    inProgress,
    recentOthersContracts,
    notifications
  },
  data() {
    return {
    
    }
  },
  computed: {
        token () {
            //return this.$root.username
            return this.$state.token
        },
        userInfo () {
          return this.$state.userInfo
        },
        store () {
          return this.$state
        },
        isMobile () {
          return this.$state.isMobile
        }
  },
  methods: {
    logUserOut() {
      this.$actions.logout()
    }
  },
  created() {
    
  }
}
</script>


<style lang="scss" scoped>

</style>

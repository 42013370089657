import Vue from 'vue';
import router from '@/router/router'
import { getUnixTime } from 'date-fns'
import axios from 'axios';


//let baseUrl = "http://localhost:3001"
let baseUrl = process.env.VUE_APP_API

export const state = Vue.observable({ 
    token: {},
    userInfo: {},
    user_id: "",
    username: "",
    showNav: false,
    isMobile: false,
    sessionIntiated: false,
    searchTerm: "FooBar",
    calItems: [],
    currentJobMoney: null,
    notifications: 0,
    newContract: {
        customer: {
            customerId: null,
            _id: null,
            fname: null,
            lname: null,
            address: {
                street: null,
                city: null,
                state: "NJ",
                zip: null
            },
            phones : {
                mobile: null,
                work: null,
                home: null
            },
            emails: {
                primary: null,
                secondary: null
            }
        },
        contract: {
            address: {
                street: null,
                city: null,
                state: "NJ",
                zip: null
            },
            scopeText: null,
            money: {
                Roofing: "0.00",
                Siding: "0.00",
                Gutters: "0.00",
                Windows: "0.00",
                Permits: "0.00",
                salesTax: "0.00",
                total: "0.00",
                deposit: "0.00",
                progressPayment: "0.00",
                balance: "0.00"
            },
            dates: {
                contractDate: null
            },
            location: {
                lat: null,
                lon: null
            },
            salesman: null,
            jobLabel: null,
            permitTown: null,
            jobType: null,
            marketing: null
        }
    }
})

export const actions = {
    updateToken(token, decoded) {
        console.log("Token Recieved.")
        state.token = token
        state.userInfo = decoded
    },
    updateCompany(company) {
        console.log("Updating Company Data.")
        state.company = company
    },
    updateTotal(total){
        state.currentJobTotal = total
    },
    logout() {
        localStorage.removeItem("jwt")
        state.showNav = false
        router.push("/login")
    },
    log(logInfo) {
        //log it
        //default values
        const newLog = {
            logTime: getUnixTime(new Date()),
            logMsg: logInfo.logMsg,
            logUser: logInfo.logUser || 'admin'
        }
        
        //optional
        logInfo.job ? newLog.job = logInfo.job : null
        logInfo.addInfo ? newLog.addInfo = logInfo.addInfo : null
        logInfo.emailType ? newLog.emailType = logInfo.emailType : null
        logInfo.recip ? newLog.recip = logInfo.recip : null
        logInfo.url ? newLog.url = logInfo.url : null
        logInfo.email ? newLog.email = logInfo.email : null
        console.log(newLog)

        axios.post(`${baseUrl}/log`, newLog)
    }
}

<template>
<b-card class="mb-2" no-body border-variant="dark">
    <template #header>
        <span class="monoTitle"><b>Jobs In Progress</b></span>
    </template>
    <b-list-group flush>
        <b-list-group-item v-for="job in inProgress" :key="job.contractId" :to="'/jobProfile/' + job._id">
            <b-row no-gutters>
                <b-col cols="11" md="11" class="text-truncate d-inline-block monoTitle" >
                    
                    {{job.customer.lname}}, {{job.customer.fname}}
                    <span class="font-weight-light">
                        <small>{{job.jobType}}</small>
                    </span>
                </b-col>
                <b-col cols="1" md="1">
                    <div class="float-right"><font-awesome-icon icon="chevron-right" /></div>
                </b-col>
            </b-row>
        </b-list-group-item>

    </b-list-group>
</b-card>

</template>

<script>

export default {
    name: 'inProgress',
    data() {
        return {
            inProgress: [],
        }
    },
    components: {
        
    },
    computed: {
        ready() {
           if (this.$state.sessionIntiated) {
               return true
           } else {
               return false
           }
       }
    },
    methods: {
        async getInprogress() {
            if(!this.ready){
                console.log(`Not ready yet! Waiting for initsession to complete.`)
                setTimeout(() => { this.getInprogress() }, 100)
            } else {
                
                const res = await this.$axios.get(`/job/inprogress`, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })

                let jobs = res.data
            
                jobs.forEach(job => {
                    job.icon = this.getIcon(job.jobType)
                })

                this.inProgress = jobs
            }
        },
        getIcon (jobType) {
            if(jobType.trim() === 'Rip ReRoof') {
                return {icon: 'caret-up', displayName: 'Rip Re-Roof'}
            } else if(jobType === 'Decking'){
                return {icon: 'grip-lines', DisplayName: 'Decking'}
            } else if(jobType === 'Roof Repair') {
                return {icon: 'caret-up', displayName: 'Roof Repair'}
            } else if(jobType === 'Other') {
                return {icon: 'network-wired', displayName: 'Other'}
            } else if(jobType === 'Railing') {
                return {icon: 'ruler-horizontal', displayName: 'Railing'}
            } else if(jobType === 'Gutters') {
                return {icon: 'tint', displayName: 'Gutters'}
            } else if(jobType === 'Gutter Repair') {
                return {icon: 'tint', displayName: 'Gutter Repair'}
            } else if(jobType === 'Gutter Cleaning') {
                return {icon: 'leaf', displayName: 'Gutter Cleaning'}
            } else if(jobType === 'Windows') {
                return {icon: 'border-all', displayName: 'Windows'}
            } else if(jobType === 'Window Repair') {
                return {icon: 'border-all', displayName: 'Window Repair'}
            } else if(jobType === 'Cover ReRoof') {
                return {icon: 'caret-up', displayName: 'Cover Re-Roof'}
            } else if(jobType === 'Siding') {
                return {icon: 'align-left', displayName: 'Siding'}
            } else if(jobType === 'Siding Repair') {
                return {icon: 'align-left', displayName: 'Siding Repair'}
            } else if(jobType === 'Trim Job') {
                return {icon: 'align-left', displayName: 'Trim Job'}
            } else if(jobType === 'New Construction Roof') {
                return {icon: 'caret-up', displayName: 'New Construction Roof'}
            } else {
                return {icon: 'network-wired', displayName: 'Other'}
            }
        }
        
    },
    created() {
        this.getInprogress()
    }    
}
</script>

<style lang="scss" scoped>
    
</style>
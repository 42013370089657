import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/router'
import axios from 'axios'
import VueMobileDetection from "vue-mobile-detection"
Vue.use(VueMobileDetection)


//Vue.prototype.$baseUrl = "http://localhost:3001"
//Vue.prototype.$baseUrl = "https://api.twininc.com"
//KOREY: Must change this in the store.js files as well
Vue.prototype.$baseUrl = process.env.VUE_APP_API
//Must create a .env file on server and put "VUE_APP_API = https://whateveritshouldbe"
console.log(Vue.prototype.$baseUrl)

const axiosBase = axios.create({
  baseURL: Vue.prototype.$baseUrl
})

/*
base.interceptors.request.use(async config => {
  config.headers = {
    'Authorization': `Bearer ${localStorage.getItem('jwt')}`
  }
  return config
}, error => {
  Promise.reject(error)
})

*/

axiosBase.interceptors.response.use(response => {
  return response
}, async error => {
  if (error.response.status === 403) {
    console.log("Token Expired. Redirect to login...")
    localStorage.removeItem('jwt')
    router.push('/login')
  } else {
    return Promise.reject(error)
  }
})


Vue.prototype.$axios = axiosBase

//filter to strip html tags 
// Use Like this: {{ someTextWithHtml | stripHTML }}
Vue.filter('stripHTML', function (value) {
  const div = document.createElement('div')
  div.innerHTML = value
  let text = div.textContent || div.innerText || ''
  let shortText = text.substring(0, 240) + "..."
  return shortText
})

//filter to capitalize first char of each word in a string 
// Use Like this:  {{ someString | capitalizeEachWord }}
Vue.filter('capitalizeEachWord', function (data) {
  let capitalized = []
  data.split(' ').forEach(word => {
    capitalized.push(
      word.charAt(0).toUpperCase() +
      word.slice(1).toLowerCase()
    )
  })
  return capitalized.join(' ')
})

//font-awesome 6
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
/* add icons to the library */
library.add(fas, far)
/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)


//bootstrap
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import './assets/style-overrides.scss'
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false


import { state, actions } from '@/store/store.js'
Vue.prototype.$state = state
Vue.prototype.$actions = actions


new Vue({
  router,
  render: h => h(App)
})
.$mount('#app')

<template>
<div>
  <b-navbar v-if="!isMobile" toggleable="md" class="desktopNavBar" variant="light" type="light" :sticky="true">
    <b-navbar-brand class="desktopBrand" href="/">
      <div class="desktopLogoWrapper">
        <img src="/img/navLogo.png" class="desktopLogo" />
      </div>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item href="/" class="monoTitle"><font-awesome-icon icon="home"/> Home</b-nav-item>
        <b-nav-item href="/newContract" class="monoTitle"><font-awesome-icon icon="folder-plus"/> New Proposal</b-nav-item>
        <b-nav-item href="/jobs" class="monoTitle"><font-awesome-icon icon="list-alt"/> Jobs</b-nav-item>
        <b-nav-item href="/serviceBoard" class="monoTitle"><font-awesome-icon icon="tools"/> Service</b-nav-item>
        <b-nav-item-dropdown  class="monoTitle">
            <template #button-content>
                <font-awesome-icon icon="toolbox"/> More
            </template>
          <b-dropdown-item href="/accountsRecievable">Acounts Recievable</b-dropdown-item>
          <b-dropdown-item href="/salesByMonth">Sales by Month</b-dropdown-item>
          <b-dropdown-item href="#" disabled>Pricing</b-dropdown-item>
          <b-dropdown-item href="/repeatJobs">Repeat Jobs</b-dropdown-item>
          <b-dropdown-item href="/creditCardActivity">Credit Card Activity</b-dropdown-item>
          <b-dropdown-item href="#" disabled>View Logs</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <b-form-input size="sm" class="mr-sm-2 monoTitle" placeholder="Search" v-model="searchTerm"></b-form-input>
          <b-button size="sm" class="my-2 my-sm-0" type="submit" @click.prevent="search()" ><font-awesome-icon icon="search"/> </b-button>
        </b-nav-form>

        <b-nav-item-dropdown right class="monoTitle">
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <font-awesome-icon icon="user-astronaut"/> {{username}} <b-badge variant="danger">{{ notifications }}</b-badge>
          </template>
          <b-dropdown-item href="/notificationBoard">Notifications <b-badge variant="danger">{{ notifications }}</b-badge></b-dropdown-item>
          <b-dropdown-item href="/settings">Profile</b-dropdown-item>
          <b-dropdown-item href="/settings">Settings</b-dropdown-item>
          <b-dropdown-item href="/adminSettings" v-if="userInfo.admin" class="text-danger" >Admin</b-dropdown-item>
          <b-dropdown-item @click.prevent="logOut()">Logout</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>

  <b-nav v-if="isMobile" class="mobileNav" justified>
    <b-nav-item @click="navBack" :class="{invisible: !showBack}" class="text-left text-light mt-2" >
      <font-awesome-icon class="text-light" icon="fa-solid fa-chevron-left" /> Go Back
    </b-nav-item>
    <b-nav-item :class="{'text-left': !showBack}" href="/">
      <img src="/img/navLogo.png" class="mobileLogo" /> <div v-if="!showBack" class="d-inline ml-1">TWIN</div>
    </b-nav-item>
    <b-nav-item-dropdown no-caret v-show="showSettingsBtn" class="text-right">
      <template #button-content>
        <font-awesome-icon icon="fa-solid fa-bars" size="xl" class="text-light" transform="down-6 left-4"/>
      </template>
      <b-dropdown-item href="/accountsRecievable" v-if="userInfo.admin" >Accts Recievable</b-dropdown-item>
      <b-dropdown-item href="/salesByMonth">Sales by Month</b-dropdown-item>
      <b-dropdown-item href="#" disabled>Pricing</b-dropdown-item>
      <b-dropdown-item href="/repeatJobs">Repeat Jobs</b-dropdown-item>
      <b-dropdown-item @click="logOut"><font-awesome-icon icon="fa-solid fa-right-from-bracket" /> Logout</b-dropdown-item>
      <b-dropdown-item disabled><font-awesome-icon icon="fa-solid fa-gear" /> Settings</b-dropdown-item>
    </b-nav-item-dropdown>
  </b-nav>
  <div v-if="showMobileSearchBar" class="mobileSearchBar">
    <b-input-group class="p-1" size="lg">
      <b-form-input id="mobileSearchFrm" v-model="searchTerm"></b-form-input>
      <b-input-group-append>
        <b-button variant="outline-primary" @click="search">Search</b-button>
      </b-input-group-append>
    </b-input-group>
  </div>

  <b-nav v-if="isMobile" class="mobileFooter" justified>
    <b-nav-item class="text-center ml-3" @click="goToJobs"><font-awesome-icon icon="fa-regular fa-handshake" /><br/>Jobs</b-nav-item>
    <b-nav-item class="text-center" @click="goToService"><font-awesome-icon icon="fa-solid fa-screwdriver" /><br/>Service</b-nav-item>
    <b-nav-item class="text-center mr-3" @click="goToNewProposal"><font-awesome-icon icon="fa-solid fa-plus" /><br/>Proposal</b-nav-item>
  </b-nav>
</div>

</template>

<script>



export default {
    name: 'navBar',
    data() {
        return {
            openNav: false,
            searchTerm: "",
        }
    },
    components: {
        
    },
    computed: { 
        username () {
            return this.$state.userInfo.name
        },
        userInfo () {
          return this.$state.userInfo
        },
        isMobile () {
          return this.$state.isMobile
        },
        showBack() {
          return this.$route.path === '/' ? false : true
        },
        showSettingsBtn() {
          return true
        },
        showMobileSearchBar() {
          if(this.$route.name === 'dashboard' && this.isMobile === true){
            return true
          } else {
            return false
          }
        },
        notifications () {
          return this.$state.notifications
        }
    },
    methods: {
        toggleNav() {
            this.openNav = !this.openNav
        },
        search() {
          this.$state.searchTerm = this.searchTerm
          
          if(this.$route.path != "/search") {
            this.$router.push({ path: `/search` })
          }
          
        },
        navBack() {

          this.$router.go(-1)
        },
        logOut() {
          this.$actions.logout()
        },
        goToJobs() {
          this.$router.push("/jobs")
        },
        goToService() {
          this.$router.push("/serviceBoard")
        },
        goToNewProposal() {
          this.$router.push("/newContract")
        }
    }
        
}
</script>

<style lang="scss" scoped>
    .mobileNav {
      display: flex;
      justify-content: space-between;
      background-color: #017BFE;
      padding: 0 0 0 0;
      color: #fff;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 100;
      height: 3.5em;
    }
    .mobileNav a {
      color: #fff;
    }
    .mobileLogo {
      width: 40px;
      height: 40px;
    }
    .desktopLogoWrapper {
      width: 60px;
      height: 50px;
      overflow: hidden;
      border: 0px solid red;
    }
    .desktopNavBar {
      padding: .25rem 1rem;
    }
    .desktopBrand {
      padding: 0;
    }
    .desktopLogo {
      transform: scale(0.3) translate(0px, -14px);
      transform-origin:top left;
    }
    .invisible {
      /*visibility: hidden;*/
      display: none
    }
    .mobileSearchBar {
      border: 0px solid black;
      z-index: 95;
      position: fixed;
      top: 56px;
      width: 100%;
      background-color: #F7F7F7;
    }
    .mobileFooter {
      display: flex;
      justify-content: space-between;
      background-color: #F7F7F7;
      padding: .5em 0 1em 0;
      color: #343A40;
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 100;
    }
    .mobileFooter a {
      color: #343A40;
    }
    .mobileFooter .nav-item .active {
      border: 1px solid #343A40;
    }
</style>

import Router from "../router/router.js"
import { isAfter } from "date-fns"
import { subMinutes } from "date-fns"
import { fromUnixTime } from "date-fns"
import jwtDecode from 'vue-jwt-decode' 
import { actions, state } from '@/store/store.js'

//import addSeconds from "date-fns/add_seconds"
//import differenceInMinutes from "date-fns/difference_in_minutes"
//import differenceInMilliSeconds from "date-fns/difference_in_milliseconds"

export {initSession}

function initSession() {
    return new Promise(() => {
        let token = localStorage.getItem("jwt")
        if(!token) {
            console.log("No token. User not logged in.")
            return Router.push("/login")
        }
        let decoded = jwtDecode.decode(token)
        console.log(decoded)
        actions.updateToken(token, decoded)

        let tokenExpiryDate = fromUnixTime(decoded.exp)
        if(!tokenExpiryDate) {
            console.log("No token expire date. User not logged in.")
            localStorage.removeItem("jwt")
            return Router.push("/login")
        }

        
        let tenMinBeforeExpiry = subMinutes(tokenExpiryDate, 10)
        //console.log(`tenMinBeforeExpiry is: ${tenMinBeforeExpiry} `)
        const now = new Date()

        //If the token has expired or will expire in the next 10 minutes
        if(isAfter(now, tenMinBeforeExpiry)) {
            console.log("Token expired/will expire within the next 10 minutes")
            localStorage.removeItem("jwt")
            return Router.push("/login")
        }

        console.log(`Token OK. Expiring at ${tokenExpiryDate}`)
        state.sessionIntiated = true
        state.showNav = true
        //setTimeout(refreshTokens, differenceInMilliSeconds(tenMinBeforeExpiry, now))
    })
}

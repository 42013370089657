<template>
<b-card class="mb-3" no-body border-variant="info" header-bg-variant="info" header-text-variant="white">
    <template #header>
        <span class="monoTitle"><b>Recent Other Contracts</b></span>
    </template>
    <b-list-group flush>
        <b-list-group-item v-for="contract in recent20" :key="contract.contractId" :to="'/jobProfile/' + contract._id">
            <b-row no-gutters>
                <b-col cols="4" md="2" class="text-truncate d-inline-block" >
                    {{format(fromUnixTime(contract.dates.contractDate), 'MM/dd/yyyy')}} 
                </b-col>
                <b-col cols="8" md="3" class="text-truncate d-inline-block monoTitle" >
                    <b>{{contract.customer.lname}}, {{contract.customer.fname}}</b>
                </b-col>
                <b-col cols="9" md="5" class="text-truncate d-inline-block" >
                    <span class="font-weight-light">
                        {{contract.siteAddress.street}} {{contract.siteAddress.city}} - <font-awesome-icon :icon="contract.icon.icon" /> 
                        <small>&nbsp;{{contract.jobType}}</small>
                    </span>
                </b-col>
                <b-col cols="2" md="1" class="text-truncate d-inline-block" >
                    <div class="float-right">
                        <span v-if="contract.sold" class="badge badge-pill badge-primary">Sold</span>
                        <span v-else class="font-weight-light"><small>Proposal</small></span>
                    </div>
                </b-col>
                <b-col cols="1" md="1">
                    <div class="float-right"><font-awesome-icon icon="chevron-right" /></div>
                </b-col>
            </b-row>
        </b-list-group-item>
    </b-list-group>
</b-card>

</template>

<script>
import { format } from 'date-fns'
import { fromUnixTime } from 'date-fns'

export default {
    name: 'recentOthersContracts',
    props: {
    },
    data() {
        return {
            recent20: [],
            format: format,
            fromUnixTime: fromUnixTime
        }
    },
    watch: {

    },
    components: {
        
    },
    computed: {
        userInfo() {
          return this.$state.userInfo
       },
       ready() {
           if (this.$state.sessionIntiated) {
               return true
           } else {
               return false
           }
       }
    },
    methods: {
        async getOthersRecent20 () {
            if(!this.ready){
                console.log(`Not ready yet! Waiting for initsession to complete.`)
                setTimeout(() => { this.getOthersRecent20() }, 100)
            } else {
                //console.log(`Auth header is: Bearer ${this.$state.token}`)

                //console.log(`get my recent 10 calledfo user: )
                
                const user = this.$state.userInfo._id
                //console.log(`User defined! user: ${user}`)

                const res = await this.$axios.get(`/job/getOthersRecent20/${user}`, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })

                //console.log(`getOthersRecent20 returned.`)
                let contracts = res.data
            
                contracts.forEach(job => {
                    job.icon = this.getIcon(job.jobType)
                })

                this.recent20 = contracts
            }

        },
        getIcon (jobType) {
            if(jobType.trim() === 'Rip ReRoof') {
                return {icon: 'caret-up', displayName: 'Rip Re-Roof'}
            } else if(jobType === 'Decking'){
                return {icon: 'grip-lines', DisplayName: 'Decking'}
            } else if(jobType === 'Roof Repair') {
                return {icon: 'caret-up', displayName: 'Roof Repair'}
            } else if(jobType === 'Other') {
                return {icon: 'network-wired', displayName: 'Other'}
            } else if(jobType === 'Railing') {
                return {icon: 'ruler-horizontal', displayName: 'Railing'}
            } else if(jobType === 'Gutters') {
                return {icon: 'tint', displayName: 'Gutters'}
            } else if(jobType === 'Gutter Repair') {
                return {icon: 'tint', displayName: 'Gutter Repair'}
            } else if(jobType === 'Gutter Cleaning') {
                return {icon: 'leaf', displayName: 'Gutter Cleaning'}
            } else if(jobType === 'Windows') {
                return {icon: 'border-all', displayName: 'Windows'}
            } else if(jobType === 'Window Repair') {
                return {icon: 'border-all', displayName: 'Window Repair'}
            } else if(jobType === 'Cover ReRoof') {
                return {icon: 'caret-up', displayName: 'Cover Re-Roof'}
            } else if(jobType === 'Siding') {
                return {icon: 'align-left', displayName: 'Siding'}
            } else if(jobType === 'Siding Repair') {
                return {icon: 'align-left', displayName: 'Siding Repair'}
            } else if(jobType === 'Trim Job') {
                return {icon: 'align-left', displayName: 'Trim Job'}
            } else if(jobType === 'New Construction Roof') {
                return {icon: 'caret-up', displayName: 'New Construction Roof'}
            } else {
                return {icon: 'network-wired', displayName: 'Other'}
            }
        }
        
    },
    created() {
        //console.log(`Created called from MyRecent Widget`)
        this.getOthersRecent20()
    }    
}
</script>

<style lang="scss" scoped>
    
</style>